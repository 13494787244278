import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import insightsLogo from "@/public/images/insights-logo.png";
import separator from "@/public/images/separator.png";
import { dmSansFont } from "./dmSans";
import { dmSerifDisplayFont } from "./dmSerifDisplay";
import { VerticalEnum } from "~/model/vertical";
import { getSponsorLogo } from "../api/sponsor";
import { format } from "date-fns";

// Utility function to convert an image from URL to base64 Data URI
async function loadImageAsBase64(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = reject;
    img.src = url;
  });
}
export const generatePDF = async (
  date: Date,
  sponsorLogoUrl: string,
  sponsorName: string
) => {
  console.log("Starting PDF generation...");
  const doc = new jsPDF("l", "pt", "a4");

  doc.addFileToVFS("dmSans-normal.ttf", dmSansFont);
  doc.addFont("dmSans-normal.ttf", "dmSans", "normal");

  doc.addFileToVFS("dmSerifDisplay-normal.ttf", dmSerifDisplayFont);
  doc.addFont("dmSerifDisplay-normal.ttf", "dmSerifDisplay", "normal");

  doc.setFillColor("#FFF6E5");
  doc.rect(
    0,
    0,
    doc.internal.pageSize.width,
    doc.internal.pageSize.height,
    "F"
  );

  const insightsLogoData = await loadImageAsBase64(insightsLogo);
  const separatorData = await loadImageAsBase64(separator);

  let sponsorLogoData = null;
  try {
    if (sponsorLogoUrl && sponsorLogoUrl !== "") {
      console.log("Fetching sponsor logo...");
      sponsorLogoData = await getSponsorLogo(sponsorLogoUrl);
    } else {
      console.log("No sponsor logo provided.");
    }

    console.log("Adding insights logo...");
    const insightsLogoX = doc.internal.pageSize.width / 2 - 100;
    const insightsLogoY = 100;
    const insightsLogoWidth = 80;
    const insightsLogoHeight = 40;
    doc.addImage(
      insightsLogoData,
      "PNG",
      insightsLogoX,
      insightsLogoY,
      insightsLogoWidth,
      insightsLogoHeight
    );

    console.log("Adding separator...");
    doc.addImage(
      separatorData,
      "PNG",
      doc.internal.pageSize.width / 2 - 1,
      100,
      1,
      42
    );

    const insightsLogoCenterY = insightsLogoY + insightsLogoHeight / 2;

    const sponsorLogoWidth = 100;
    const sponsorLogoHeight = 100;

    const sponsorLogoX = doc.internal.pageSize.width / 2 + 20;
    const sponsorLogoY = insightsLogoCenterY - sponsorLogoHeight / 2;

    if (sponsorLogoData) {
      console.log("Adding sponsor logo...");
      doc.addImage(
        sponsorLogoData,
        "PNG",
        sponsorLogoX,
        sponsorLogoY,
        sponsorLogoWidth,
        sponsorLogoHeight,
        undefined,
        "FAST"
      );
    } else {
      console.log("Adding second insights logo...");
      doc.addImage(
        insightsLogoData,
        "PNG",
        sponsorLogoX,
        insightsLogoY,
        insightsLogoWidth,
        insightsLogoHeight
      );
    }
  } catch (error) {
    console.error("Error capturing sponsor logo:", error);
  }

  console.log("Adding report title...");
  doc
    .setFontSize(60)
    .setFont("dmSerifDisplay", "normal")
    .setTextColor("#18332F")
    .text("İyi oluş raporu", doc.internal.pageSize.width / 2 - 200, 275.0);

  const now = new Date();
  const formattedStartDate = format(date, "dd.MM.yyyy");
  const formattedEndDate = format(now, "dd.MM.yyyy");
  const dateText = `${formattedStartDate} - ${formattedEndDate}`;

  console.log("Adding date text...");
  doc
    .setFontSize(20)
    .setFont("dmSans", "normal")
    .setTextColor("#18332F")
    .text(dateText, doc.internal.pageSize.width / 2 - 110, 335.0);

  // Page 2
  addNewPage(
    doc,
    "Özet (1/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    console.log("Capturing company scope...");
    const companyScopeElement = document.getElementById(
      "companyScopeContainer"
    );
    if (!companyScopeElement) {
      throw new Error("Company scope element not found");
    }
    const companyScope = await html2canvas(companyScopeElement, {
      scale: 2,
      backgroundColor: null,
    });
    const companyScopeData = companyScope.toDataURL("image/png");
    doc.addImage(
      companyScopeData,
      "PNG",
      30,
      130,
      250,
      250 / getElementRatio("companyScopeContainer")
    );

    console.log("Capturing heltia members...");
    const heltiaMembers = await html2canvas(
      document.getElementById("heltiaMembersContainer"),
      { scale: 2, backgroundColor: null }
    );
    const heltiaMembersData = heltiaMembers.toDataURL("image/png");
    doc.addImage(
      heltiaMembersData,
      "PNG",
      300,
      130,
      250,
      250 / getElementRatio("heltiaMembersContainer")
    );

    console.log("Capturing meeting held members...");
    const meetingHeldMembers = await html2canvas(
      document.getElementById("meetingHeldMembersContainer"),
      { scale: 2, backgroundColor: null }
    );
    const meetingHeldMembersData = meetingHeldMembers.toDataURL("image/png");
    doc.addImage(
      meetingHeldMembersData,
      "PNG",
      570,
      130,
      250,
      250 / getElementRatio("meetingHeldMembersContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 2:", error);
  }

  // Page 3
  addNewPage(
    doc,
    "Özet (2/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    console.log("Capturing cumulative users...");
    const usersElement = document.getElementById("cumulativeUsersContainer");
    if (!usersElement) {
      throw new Error("Cumulative users element not found");
    }
    const userChart = await html2canvas(usersElement, {
      scale: 2,
      backgroundColor: null,
    });
    const userData = userChart.toDataURL("image/png");
    doc.addImage(
      userData,
      "PNG",
      30,
      130,
      380,
      400 / getElementRatio("cumulativeUsersContainer")
    );

    console.log("Capturing cumulative meetings...");
    const meetingsChart = await html2canvas(
      document.getElementById("cumulativeMeetingsContainer"),
      { scale: 2, backgroundColor: null }
    );
    const meetingsData = meetingsChart.toDataURL("image/png");
    doc.addImage(
      meetingsData,
      "PNG",
      430,
      130,
      380,
      400 / getElementRatio("cumulativeMeetingsContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 3:", error);
  }

  // Page 4
  addNewPage(
    doc,
    "Özet (3/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    console.log("Capturing newcoming users...");
    const newComingUsersElement = document.getElementById(
      "newcomingUsersContainer"
    );
    if (!newComingUsersElement) {
      throw new Error("Newcoming users element not found");
    }
    const newUsersChart = await html2canvas(newComingUsersElement, {
      scale: 2,
      backgroundColor: null,
    });
    const newUsersData = newUsersChart.toDataURL("image/png");
    doc.addImage(
      newUsersData,
      "PNG",
      30,
      130,
      780,
      780 / getElementRatio("newcomingUsersContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 4:", error);
  }

  // Page 5
  addNewPage(
    doc,
    "Özet (4/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    console.log("Capturing active users...");
    const activeUsersElement = document.getElementById("activeUsersContainer");
    if (!activeUsersElement) {
      throw new Error("Active users element not found");
    }
    const activeUserChart = await html2canvas(activeUsersElement, {
      scale: 2,
      backgroundColor: null,
    });
    const activeUsersData = activeUserChart.toDataURL("image/png");
    doc.addImage(
      activeUsersData,
      "PNG",
      30,
      130,
      780,
      780 / getElementRatio("activeUsersContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 5:", error);
  }

  console.log("Adding vertical pages...");
  await addVerticalPages(
    doc,
    VerticalEnum.All,
    1,
    "Tüm destek alanlarında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.MentalHealth,
    3,
    "Mental sağlık alanında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.Nutrition,
    5,
    "    Beslenme alanında seanslar    ",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.PhysicalHealth,
    7,
    "Fiziksel sağlık alanında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.Other,
    9,
    "Diğer destek alanlarında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );

  // Page 9
  addNewPage(
    doc,
    "Pozitif Etki (1/2)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    console.log("Capturing checkups...");
    const checkupsElement = document.getElementById("checkupsContainer");
    if (!checkupsElement) {
      throw new Error("Checkups element not found");
    }
    const checkupsChart = await html2canvas(checkupsElement, {
      scale: 2,
      backgroundColor: null,
    });
    const checkupsData = checkupsChart.toDataURL("image/png");
    doc.addImage(
      checkupsData,
      "PNG",
      30,
      130,
      780,
      780 / getElementRatio("checkupsContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 9:", error);
  }

  // Page 10
  addNewPage(
    doc,
    "Pozitif Etki (2/2)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    console.log("Capturing average meeting durations...");
    const averageMeetingDurationsElement = document.getElementById(
      "averageMeetingDurationsContainer"
    );
    if (!averageMeetingDurationsElement) {
      throw new Error("Average meeting durations element not found");
    }
    const averageMeetingDurationsDiv = await html2canvas(
      averageMeetingDurationsElement,
      { scale: 2, backgroundColor: null }
    );
    const averageMeetingDurations =
      averageMeetingDurationsDiv.toDataURL("image/png");
    doc.addImage(
      averageMeetingDurations,
      "PNG",
      60,
      130,
      280,
      280 / getElementRatio("averageMeetingDurationsContainer")
    );

    console.log("Capturing average content consumption...");
    const averageContentConsumptionDiv = await html2canvas(
      document.getElementById("averageContentConsumptionContainer"),
      { scale: 2, backgroundColor: null }
    );
    const averageContentConsumption =
      averageContentConsumptionDiv.toDataURL("image/png");
    // Calculate the x position for the second image with a gap of 40 pixels
    const firstImageWidth = 280;
    const gap = 40;
    const secondImageX = 60 + firstImageWidth + gap;

    doc.addImage(
      averageContentConsumption,
      "PNG",
      secondImageX,
      130,
      280,
      280 / getElementRatio("averageContentConsumptionContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 10:", error);
  }

  addNewPage(
    doc,
    "İçerikler",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );

  try {
    console.log("Capturing popular contents...");
    const popularContentsElement = document.getElementById(
      "popularContentsContainer"
    );
    if (!popularContentsElement) {
      throw new Error("Popular contents element not found");
    }

    const popularContents = await html2canvas(popularContentsElement, {
      scale: 2,
      backgroundColor: null,
    });
    const popularContentsData = popularContents.toDataURL("image/png");

    console.log("Capturing content types...");
    const contentTypesElement = document.getElementById(
      "contentTypesContainer"
    );
    if (!contentTypesElement) {
      throw new Error("Content types element not found");
    }
    const contentTypes = await html2canvas(contentTypesElement, {
      scale: 2,
      backgroundColor: null,
    });
    const contentTypesData = contentTypes.toDataURL("image/png");

    const elementWidth = 380; // Half of the page width minus margins
    const popularContentsHeight =
      elementWidth / getElementRatio("popularContentsContainer");
    const contentTypesHeight =
      elementWidth / getElementRatio("contentTypesContainer");

    const elementHeight = Math.max(popularContentsHeight, contentTypesHeight);

    doc.addImage(
      popularContentsData,
      "PNG",
      30,
      130,
      elementWidth,
      elementHeight
    );

    doc.addImage(
      contentTypesData,
      "PNG",
      430,
      130,
      elementWidth,
      elementHeight
    );
  } catch (error) {
    console.error("Error capturing elements for page 11:", error);
  }

  // Page 11
  addNewPage(
    doc,
    "Demografi",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    console.log("Capturing demography gender...");
    const demographyElement = document.getElementById(
      "demographyGenderContainer"
    );
    if (!demographyElement) {
      throw new Error("Demography gender element not found");
    }
    const demographyGenderContainer = await html2canvas(demographyElement, {
      scale: 2,
      backgroundColor: null,
    });
    const demographyGender = demographyGenderContainer.toDataURL("image/png");
    doc.addImage(
      demographyGender,
      "PNG",
      30,
      130,
      250,
      250 / getElementRatio("demographyGenderContainer")
    );

    console.log("Capturing demography age...");
    const demographyAgeContainer = await html2canvas(
      document.getElementById("demographyAgeContainer"),
      { scale: 2, backgroundColor: null }
    );
    const demographyAge = demographyAgeContainer.toDataURL("image/png");
    doc.addImage(
      demographyAge,
      "PNG",
      300,
      130,
      250,
      250 / getElementRatio("demographyAgeContainer")
    );

    console.log("Capturing demography parenthood...");
    const demographyParenthoodContainer = await html2canvas(
      document.getElementById("demographyParenthoodContainer"),
      { scale: 2, backgroundColor: null }
    );
    const demographyParenthood =
      demographyParenthoodContainer.toDataURL("image/png");
    doc.addImage(
      demographyParenthood,
      "PNG",
      570,
      130,
      250,
      250 / getElementRatio("demographyParenthoodContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 12:", error);
  }

  console.log("Adding last page...");
  addNewPage(doc, "", insightsLogoData, separatorData, sponsorLogoData);
  doc
    .setFont("dmSerifDisplay", "normal")
    .setFontSize(72)
    .setTextColor("#18332F")
    .text("Teşekkürler", doc.internal.pageSize.width / 2 - 190, 280.0);

  doc.save(
    `HeltiaInsights_${sponsorName.replace(/\s+/g, "")}_${format(
      now,
      "ddMMyyyy"
    )}.pdf`
  );
  console.log("PDF generation completed.");
};

function addNewPage(
  doc,
  title,
  insightsLogoData,
  separatorData,
  sponsorLogoData
) {
  console.log(`Adding new page: ${title}`);
  doc.addPage();
  doc.setFont("dmSans");
  doc.setFillColor("#FFF6E5");
  doc.rect(
    0,
    0,
    doc.internal.pageSize.width,
    doc.internal.pageSize.height,
    "F"
  );
  doc
    .setFont("dmSerifDisplay", "normal")
    .setFontSize(24)
    .setTextColor("#18332F")
    .text(title, doc.internal.pageSize.width / 2 - 80, 50.0);

  const logoY = 520; // Vertical position for logos
  const insightsLogoWidth = 80;
  const insightsLogoHeight = 40;
  const sponsorLogoWidth = 100;
  const sponsorLogoHeight = 100;

  // Calculate the vertical center of the insights logo
  const insightsLogoCenterY = logoY + insightsLogoHeight / 2;

  // Adjust the vertical position of the sponsor logo to align with the insights logo
  const adjustedSponsorLogoY = insightsLogoCenterY - sponsorLogoHeight / 2;

  // Add insights logo on the left
  doc.addImage(
    insightsLogoData,
    "PNG",
    doc.internal.pageSize.width / 2 - 100,
    logoY,
    insightsLogoWidth,
    insightsLogoHeight
  );

  // Add separator in the middle
  doc.addImage(
    separatorData,
    "PNG",
    doc.internal.pageSize.width / 2 - 1,
    logoY,
    1,
    40
  );

  // Conditionally add sponsor logo or second insights logo on the right
  if (sponsorLogoData) {
    console.log("Adding sponsor logo...");
    doc.addImage(
      sponsorLogoData,
      "PNG",
      doc.internal.pageSize.width / 2 + 10,
      adjustedSponsorLogoY,
      sponsorLogoWidth,
      sponsorLogoHeight
    );
  } else {
    console.log("Adding second insights logo...");
    doc.addImage(
      insightsLogoData,
      "PNG",
      doc.internal.pageSize.width / 2 + 10,
      logoY,
      insightsLogoWidth,
      insightsLogoHeight
    );
  }
}

async function addVerticalPages(
  doc,
  vertical,
  pageIndex,
  subtitle,
  insightsLogoData,
  separatorData,
  sponsorLogoData
) {
  console.log(`Adding vertical pages for ${vertical} (${pageIndex}/10)`);
  addNewPage(
    doc,
    `Seanslar (${pageIndex}/10)`,
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  doc
    .setFont("dmSans", "normal")
    .setFontSize(12)
    .setTextColor("#18332F")
    .text(subtitle, doc.internal.pageSize.width / 2 - 90, 80.0);

  try {
    console.log(`Capturing ${vertical} meetings...`);
    const meetingsElement = document.getElementById(`${vertical}Meetings`);
    if (!meetingsElement) {
      throw new Error(`${vertical} meetings element not found`);
    }
    const meetingsChart = await html2canvas(meetingsElement, {
      scale: 2,
      backgroundColor: null,
    });
    const meetings = meetingsChart.toDataURL("image/png");
    doc.addImage(
      meetings,
      "PNG",
      30,
      130,
      380,
      400 / getElementRatio(`${vertical}Meetings`)
    );

    console.log(`Capturing ${vertical} users...`);
    const usersChart = await html2canvas(
      document.getElementById(`${vertical}Users`),
      { scale: 2, backgroundColor: null }
    );
    const users = usersChart.toDataURL("image/png");
    doc.addImage(
      users,
      "PNG",
      430,
      130,
      380,
      400 / getElementRatio(`${vertical}Users`)
    );

    addNewPage(
      doc,
      `Seanslar (${pageIndex + 1}/10)`,
      insightsLogoData,
      separatorData,
      sponsorLogoData
    );
    doc
      .setFont("dmSans", "normal")
      .setFontSize(12)
      .setTextColor("#18332F")
      .text(subtitle, doc.internal.pageSize.width / 2 - 90, 80.0);

    console.log(`Capturing ${vertical} meetings per user...`);
    const meetingsPerUserElement = document.getElementById(
      `${vertical}MeetingsPerUser`
    );
    if (!meetingsPerUserElement) {
      throw new Error(`${vertical} meetings per user element not found`);
    }
    const meetingsPerUserChart = await html2canvas(meetingsPerUserElement, {
      scale: 2,
      backgroundColor: null,
    });
    const meetingsPerUser = meetingsPerUserChart.toDataURL("image/png");
    doc.addImage(
      meetingsPerUser,
      "PNG",
      30,
      130,
      780,
      780 / getElementRatio(`${vertical}MeetingsPerUser`)
    );
  } catch (error) {
    console.error(
      `Error capturing elements for ${vertical} vertical pages:`,
      error
    );
  }
}

function getElementRatio(elementId: string): number {
  const element = document.getElementById(elementId);
  if (!element) {
    throw new Error(`Element with ID ${elementId} not found`);
  }
  return element.offsetWidth / element.offsetHeight;
}
